import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/jon/projects/shanti-aid-gatsby/src/components/DefaultNewsLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`QA Level 3 Award in Paediatric First Aid`}</h1>
    <h2>{`40% of our service users have gained their QA Level 3 Award in Paediatric First Aid`}</h2>
    <hr></hr>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/44115fda98a4159f4eb47447a25a927c/c1b63/qa-training.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAC4jAAAuIwF4pT92AAACLklEQVQoz62SzU+SAQDG37+gTrXmpUt6aWurlYpaSXwJKpAbln1Zlh8DEQFfwXgFAxWNJDUMYRJhbq21Lm3Vtdqcm1MztPgwda2/A34tu7VOzmf7HZ7Ds+fyE4rFIgdBoVCgWCggABTZf/7dCsMLXkZeSATmBwik7uNPuvEmRMRoN2LMSl/UsofjqRlnpBPXvIMHzyQ+LMT58vENa2+nELtuEQ0P8f7VY4SWISNNHg3NUh1Glwp9nwK9KEfVW4nKIUPllKGwV1DbcxaF/Rxav5I2t4yw5w7e7quIpgpOHD1EaclhTA3lCMGYhG+mn4m5AO6QFXHcghTu5YbbgNZ6HoNDjsEp3zsz+XQYvA3oOhuZ8Fk4fbKM48eO0KKpJjxgxtR8BSE468EfcWEf7UAK2ZEe2ekcvI7JqUNnvUBddw31tovoRQVGtxJNrxxNu5pUyEJ7vYxTpSUkR7qJ+szcbdYidPhv0jXcSku/gWuikTaphSaHFoNdjdpSg9JchaqnBmVPFSpbNQpbJZdtZ+iymjA01nJbX05f6yXUlWX47ikR+qeshJIBxuKD2Mc7GJrpZzTmYTI5jGvSQiDmZjI1wnQqyPTzMZ4kgyRSE8wmZglPTbAQj/ByLkJ86iHvXicQlr8ukt/6TiazzuLqJ9Y3V1jfWCaXTbO09pmV9BKb31bJ5TbIZtPkMml2tnL83Mqxm8+Qz2b4kc3wayfP7vb2Xw8PMsIfw/dD8T+9WCzwGzCCk9EGY1chAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Qualsafe",
            "title": "Qualsafe",
            "src": "/static/44115fda98a4159f4eb47447a25a927c/5a190/qa-training.png",
            "srcSet": ["/static/44115fda98a4159f4eb47447a25a927c/772e8/qa-training.png 200w", "/static/44115fda98a4159f4eb47447a25a927c/e17e5/qa-training.png 400w", "/static/44115fda98a4159f4eb47447a25a927c/5a190/qa-training.png 800w", "/static/44115fda98a4159f4eb47447a25a927c/c1b63/qa-training.png 1200w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Paediatric First Aid (RQF)`}</p>
    <p>{`2 day course`}</p>
    <p>{`certification valid for 3 years`}</p>
    <p>{`Ofqal regulated`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      